import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import split from 'lodash/split'

import { _ } from 'Services/I18n'

import {
  Container,
  Dash,
  StyledTitle,
  Content,
  Col,
  SubTitle,
  Text,
  AlarmIcon,
} from './styles'

const Opening = ({ color, hours }) => {
  if (!hours) {
    return null
  }

  const text = split(hours, /<br\s?\/>/)
  const wrappedText = map(text, item => `<p>${item}</p>`).join('')

  return (
    <Container>
      <StyledTitle>{_('common.tradingHours')}</StyledTitle>
      <Dash />
      <Content>
        <Col>
          <SubTitle mb="11px">
            <AlarmIcon color={color} />
            {_('common.standardHours')}
          </SubTitle>
          <Text
            dangerouslySetInnerHTML={{
              __html: text.length === 0 ? hours : wrappedText,
            }}
          />
        </Col>
      </Content>
    </Container>
  )
}

Opening.defaultProps = {
  color: '',
  hours: '',
}

Opening.propTypes = {
  color: PropTypes.string,
  hours: PropTypes.string,
}

export default Opening
