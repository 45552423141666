import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import get from 'lodash/get'

import { _ } from 'Services/I18n'

import { DAYS } from 'Constants/ids'

import {
  SubTitle,
  ClockIcon,
  DayRow,
  DayName,
  StatusDot,
  DayHours,
  CloseLabel,
} from './styles'

const getHoursEnd = day => {
  const hours = get(day, 'hours_end')

  return hours === '23:45' ? 'Midnight' : hours
}

const OpeningHours = ({ color, openingHours }) => (
  <>
    <SubTitle mb="11px">
      <ClockIcon color={color} />
      {_('common.tradingHours')}
    </SubTitle>

    {map(openingHours, (entity, index) => (
      <DayRow bold={Number(index) === new Date().getDay() ? 1 : 0} key={index}>
        {get(entity, 'hours_start') === 'closed' ? (
          <>
            <StatusDot open={0} />
            <DayName>{_(DAYS[index - 1])}</DayName>
            <CloseLabel flex="1" justifyContent="flex-end">
              {_('common.closed')}
            </CloseLabel>
          </>
        ) : (
          <>
            <StatusDot open={1} />
            <DayName>{_(DAYS[index - 1])}</DayName>
            <DayHours>
              {get(entity, 'hours_start')} - {getHoursEnd(entity)}
            </DayHours>
          </>
        )}
      </DayRow>
    ))}
  </>
)

OpeningHours.defaultProps = {
  color: '',
  openingHours: [],
}

OpeningHours.propTypes = {
  color: PropTypes.string,
  openingHours: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default OpeningHours
